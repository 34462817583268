body {
    margin: 0;
    font-family: -apple-system, BlinkMaxSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #323232;
}

code {
    font-family: source-code-pro, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
#root {
    height: 100%;
    background-color: #323232;
}