h1 {
    margin-top: 0px;
}

p {
    margin-top: 0px;
}

.qr-image-wrapper section div {
    box-shadow: unset !important;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.root {
    height: 100%;
    background-color: #888;
}